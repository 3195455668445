<template>
  <div class="game-container">
    <FindElementGame
      :bgImg="bgImg"
      :imgList="imgList"
      :lessonNo="35"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import FindElementGame from "@/components/Course/GamePage/FindElementGame";
export default {
  data() {
    return {
      titleInfo: {
        hanzi: "找鼻子",
        pinyin: " zhǎo bízi",
      },
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/background.svg"),
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/clown-done.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/cow-done.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/chick-done.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/snowman-done.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-35-1/dino-done.svg"),
        },
      ],
    };
  },
  components: {
    FindElementGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
