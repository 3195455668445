var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("DragToGroupCakeGame", {
        attrs: {
          bgImgList: _vm.bgImgList,
          bgImgArr: _vm.bgImgArr,
          optionImgList: _vm.optionImgList,
          lessonNo: 35,
          buttonList: _vm.buttonList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }