<template>
  <div class="game-container">
    <DragToGroupCakeGame
      :bgImgList="bgImgList"
      :bgImgArr="bgImgArr"
      :optionImgList="optionImgList"
      :lessonNo="35"
      :buttonList="buttonList"
    />
  </div>
</template>

<script>
import DragToGroupCakeGame from "@/components/Course/GamePage/DragToGroupCakeGame";
export default {
  data() {
    return {
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
      bgImgArr: [
        require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/background-1.svg"),
        require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/background-2.svg"),
        require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/background-3.svg"),
        require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/background-4.svg"),
      ],
      bgImgList: [
        [
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-1.svg"),
            id: 1,
            index: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-2.svg"),
            id: 2,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-3.svg"),
            id: 3,
            index: 3,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-4.svg"),
            id: 4,
            index: 4,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-5.svg"),
            id: 5,
            index: 5,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1-6.svg"),
            id: 6,
            index: 6,
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-1.svg"),
            id: 1,
            index: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-2.svg"),
            id: 2,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-3.svg"),
            id: 3,
            index: 3,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-4.svg"),
            id: 4,
            index: 4,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-5.svg"),
            id: 5,
            index: 5,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2-6.svg"),
            id: 6,
            index: 6,
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-1.svg"),
            id: 1,
            index: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-2.svg"),
            id: 2,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-3.svg"),
            id: 3,
            index: 3,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-4.svg"),
            id: 4,
            index: 4,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-5.svg"),
            id: 5,
            index: 5,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3-6.svg"),
            id: 6,
            index: 6,
          },
        ],
        [
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-1.svg"),
            id: 1,
            index: 1,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-2.svg"),
            id: 2,
            index: 2,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-3.svg"),
            id: 3,
            index: 3,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-4.svg"),
            id: 4,
            index: 4,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-5.svg"),
            id: 5,
            index: 5,
          },
          {
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4-6.svg"),
            id: 6,
            index: 6,
          },
        ],
      ],
      optionImgList: [
        [
          {
            id: 1,
            index: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1.svg"),
          },
          {
            id: 2,
            index: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2.svg"),
          },
          {
            id: 3,
            index: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3.svg"),
          },
          {
            id: 4,
            index: 4,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4.svg"),
          },
          {
            id: 5,
            index: 5,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-5.svg"),
          },
          {
            id: 6,
            index: 6,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-6.svg"),
          },
        ],
        [
          {
            id: 1,
            index: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1.svg"),
          },
          {
            id: 2,
            index: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2.svg"),
          },
          {
            id: 3,
            index: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3.svg"),
          },
          {
            id: 4,
            index: 4,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4.svg"),
          },
          {
            id: 5,
            index: 5,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-5.svg"),
          },
          {
            id: 6,
            index: 6,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-6.svg"),
          },
        ],
        [
          {
            id: 1,
            index: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1.svg"),
          },
          {
            id: 2,
            index: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2.svg"),
          },
          {
            id: 3,
            index: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3.svg"),
          },
          {
            id: 4,
            index: 4,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4.svg"),
          },
          {
            id: 5,
            index: 5,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-5.svg"),
          },
          {
            id: 6,
            index: 6,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-6.svg"),
          },
        ],
        [
          {
            id: 1,
            index: 1,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-1.svg"),
          },
          {
            id: 2,
            index: 2,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-2.svg"),
          },
          {
            id: 3,
            index: 3,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-3.svg"),
          },
          {
            id: 4,
            index: 4,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-4.svg"),
          },
          {
            id: 5,
            index: 5,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-5.svg"),
          },
          {
            id: 6,
            index: 6,
            image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-35-2/hair-6.svg"),
          },
        ],
      ],
    };
  },
  components: {
    DragToGroupCakeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











