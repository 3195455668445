var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "darg-to-dress-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNo },
        [
          _c("SceneSwitchThumbnails", {
            attrs: { buttons: _vm.buttonList, numBers: _vm.currentIndex + 1 },
            on: { changeTheNumbers: _vm.changeTheNumbers },
          }),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c("img", {
                staticClass: "bg-img",
                attrs: { src: _vm.bgImgArr[this.currentIndex] },
              }),
              _vm._l(_vm.bgImgList[_vm.currentIndex], function (item, index) {
                return [
                  _vm.dropId === item.id
                    ? _c("img", {
                        key: index + "bg",
                        staticClass: "bg-img-item",
                        attrs: { src: item.image },
                      })
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c("div", {
            staticClass: "drop-area",
            class: "dropBox-" + (_vm.currentIndex + 1),
            attrs: { draggable: "true" },
            on: {
              drop: function ($event) {
                $event.stopPropagation()
                return _vm.handleDropImg($event)
              },
              dragover: function ($event) {
                $event.stopPropagation()
                return _vm.allowDrop($event)
              },
            },
          }),
          _c(
            "div",
            { staticClass: "drag-area" },
            _vm._l(_vm.optionImgList[_vm.currentIndex], function (item, index) {
              return _c(
                "div",
                {
                  key: index + "drag",
                  staticClass: "option-item",
                  class: "option-" + item.id,
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.dispearIdList.indexOf(item.id) === -1,
                        expression: "dispearIdList.indexOf(item.id) === -1",
                      },
                    ],
                    class: { notShow: _vm.dragId === item.id },
                    attrs: { src: item.image, draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleDragImg($event, item.id)
                      },
                      drop: function ($event) {
                        $event.stopPropagation()
                        return _vm.Sdrop($event)
                      },
                      dragend: function ($event) {
                        $event.stopPropagation()
                        return _vm.dragend()
                      },
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }