<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11" :titleInfo="titleInfo"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
          titleInfo:{
hanzi:"很……",
pinyin:"hěn"
    },
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "hěn hǎo",
          hanzi: "很好",
          englishClass:"font-english-medium",
          english: "very good",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-35/card-1-hen-hao.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "hěn ài",
          hanzi: "很爱",
          englishClass:"font-english-medium",
          english: "to love very much",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-35/card-2-hen-ai.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "hěn xiǎo",
          hanzi: "很小",
          englishClass:"font-english-medium",
          english: "very small",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-35/card-3-hen-xiao.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "hěn dà",
          hanzi: "很大",
          englishClass:"font-english-medium",
          english: "very big",
          image: require(`@/assets/img/16-GAMES/G62-vocab-cards/lesson-35/card-4-hen-da.svg`),
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
