<template>
  <div class="darg-to-dress-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNo">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex + 1"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="background-area">
        <img :src="bgImgArr[this.currentIndex]" class="bg-img" />
        <template v-for="(item, index) in bgImgList[currentIndex]">
          <img
            :src="item.image"
            class="bg-img-item"
            :key="index + 'bg'"
            v-if="dropId === item.id"
          />
        </template>
      </div>
      <div
        class="drop-area"
        :class="'dropBox-' + (currentIndex + 1)"
        draggable="true"
        @drop.stop="handleDropImg($event)"
        @dragover.stop="allowDrop($event)"
      ></div>

      <div class="drag-area">
        <div
          v-for="(item, index) in optionImgList[currentIndex]"
          class="option-item"
          :class="'option-' + item.id"
          :key="index + 'drag'"
        >
          <img
            :class="{ notShow: dragId === item.id }"
            v-show="dispearIdList.indexOf(item.id) === -1"
            :src="item.image"
            draggable="true"
            @dragstart.stop="handleDragImg($event, item.id)"
            @drop.stop="Sdrop($event)"
            @dragend.stop="dragend()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "DragToGroupCakeGame",
  props: {
    optionImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgArr: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNo: {
      type: Number,
      require: true,
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      currentIndex: 0,
      isLastStep: false,
      showHnaziIndex: false,
      dispearIdList: [],
      dropIdList: [],
      dragId: null,
      dropId: null,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      dropImgSocketInfo: {},
      dragImgSocketInfo: {},
      clickMenuSocketInfo: {},
      step: 0,
    };
  },
  watch: {
    dropImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005701,
          data: { value },
          text: "DragToGressGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005702,
          data: { value },
          text: "DragToGressGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2005703,
          data: { value },
          text: "DragToGressGame menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropImgEvent", () => {
      this.handleDropImg("socket", true);
    });
    this.$bus.$on("dragImgEvent", ({ id }) => {
      this.handleDragImg("socket", id, true);
    });
    this.$bus.$on("dragImgEventMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropImgEvent");
    this.$bus.$off("dragImgEvent");
    this.$bus.$off("dragImgEventMenu");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      this.currentIndex = index - 1;
      this.dropIdList = [];
      this.dragId = null;
      this.dropId = null;
      this.step = 0;
      this.dispearIdList = [];
    },
    handleDragImg(event, id, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.dragId = id;
      if (this.dragId === id) {
        console.log("拖拽中");
      }
    },
    handleDropImg(event, isFromSocket = false) {
      console.log("拖拽成功");
      if (!isFromSocket) {
        this.dropImgSocketInfo = {
          value: Math.random(),
        };
      }

      this.dispearIdList = [];
      this.dispearIdList.push(this.dragId);
      this.dropId = this.dragId;
      playCorrectSound(true, false);
      if (this.dropIdList.indexOf(this.dropId) !== -1) {
        return;
      }
      this.dropIdList.push(this.dropId);
      this.step++;
      if (this.isLastStep) {
        return;
      }
      if (this.currentIndex === this.bgImgList.length - 1) {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      }
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
    dragend() {
      this.dragId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.option-List {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.title-area {
  z-index: 11;
  position: absolute;
  left: 0;
  top: 10%;
  width: 30%;
  height: 22%;
  background: #cd4c3f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 22px;
  color: #fff;
  .title {
    .title-pinyin,
    .title-hanzi {
      text-align: center;
    }
  }
}
.darg-to-dress-game-container {
  background: #fff;
  border-radius: 48px;
  position: relative;
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      position: relative;
      //   width: fit-content;
      .bg-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .drop-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .drag-area {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      img {
        cursor: pointer;
        width: 90%;
        -webkit-user-drag: inherit;
      }
      .option-item {
        position: absolute;
        -webkit-user-drag: auto;
        z-index: 99;
      }
      .notShow {
        opacity: 0;
      }
      .dispear {
        opacity: 0;
      }
    }
  }
  .lesson-35 {
    .drag-area {
      .option-1 {
        top: 30%;
        left: 2%;
        width: 10%;
      }
      .option-2 {
        top: 29%;
        left: 16%;
        width: 11%;
      }
      .option-3 {
        top: 43%;
        left: 79%;
        width: 10%;
      }
      .option-4 {
        top: 8%;
        left: 76%;
        width: 10%;
      }
      .option-5 {
        top: 42%;
        left: 66%;
        width: 9%;
      }
      .option-6 {
        top: 12%;
        left: 61%;
        width: 14%;
      }
    }
  }
  .lesson-35 {
    .dropBox-1 {
      // background: #000;
      display: block;
      position: absolute;
      width: 11%;
      height: 16%;
      top: 29%;
      left: 42%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropBox-2 {
      // background: #000;
      display: block;
      position: absolute;
      width: 11%;
      height: 16%;
      top: 29%;
      left: 42%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropBox-3 {
      // background: #000;
      display: block;
      position: absolute;
      width: 11%;
      height: 16%;
      top: 8%;
      left: 43%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .dropBox-4 {
      // background: #000;
      display: block;
      position: absolute;
      width: 11%;
      height: 16%;
      top: 21%;
      left: 45%;
      z-index: 8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
